@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@tailwind base;
@tailwind components;

body {
  @apply bg-background;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}

.tooltip-panel {
  @apply opacity-0 transition-all duration-300 invisible;
}

.tooltip:hover .tooltip-panel,
.tooltip-panel:hover {
  @apply opacity-100 z-10 visible;
}

.tooltip::after {
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  width: 150%;
  top: 100%;
}

.slick-track {
  height: min-content !important;
}

.slide-anime-image {
  height: 20rem;
}

.slick-dots li button:before {
  @apply text-gray-400;
}

.slick-dots li.slick-active button:before {
  @apply text-white;
}

::-webkit-scrollbar {
  @apply w-1;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-background-darker;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-400 rounded-md;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-80;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

@tailwind utilities;
